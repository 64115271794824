import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Gutter } from '../stylesheets/components';
import { withRouter } from 'react-router';

const HeaderContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  border-bottom: 1px solid black;
  padding: 15px 30px;
  display: flex;
  z-index:999;
  background-color: #FAF3E6;
  justify-content: space-between;
  width: calc(100% - 60px);
`;

const HeaderLink = styled.a`
  text-decoration: none;
  margin-right: 20px;
  color: #AAA;
  cursor: not-allowed;
  &.active {
    cursor: pointer;
    color: black;
  }
`

const Column = styled.div`
  
  font-size: 0.7em;
`;

class Header extends Component {
  handleClick(url, path, e) {
    e.preventDefault();
    if (this.isActive(path)) {
      this.props.history.push(url);
    }
  }

  decisionValidate(){
    let allDecisionsValidated = true;
    let { decisions } = this.props;
    // debugger;
    if (decisions.length != 25) {
      return false;
    }
    _.each(decisions, decision => {
      if (!this.validateDecision(decision)){
        allDecisionsValidated = false;
      }   
    })
    return allDecisionsValidated;
  }

  demographicValidate(){
    let { currentUser } = this.props;

    if (currentUser.is_currently_renting) {
      return currentUser.is_currently_renting && currentUser.number_of_unit && currentUser.gender && currentUser.ethnicity && currentUser.income;
    } else {
      return !_.isNull(currentUser.is_currently_renting) && currentUser.gender && currentUser.ethnicity && currentUser.income;
    }

  }

  isActive(url){
    let currentPath = this.props.match.path;

    if (currentPath === "/survey/demographic" || currentPath === "/survey/tutorial") {
      if (url === "/survey/open-questions") {
        return this.decisionValidate();
      } else if (url === "/survey/reports/:idx") {
        return this.demographicValidate();
      } else {
        return url === "/survey/demographic";
      }


    } else if (currentPath === "/survey/reports/:idx") {
      if (url === "/survey/open-questions") {
        return this.decisionValidate();
      } else if (url === "/survey/reports/:idx") {
        return this.demographicValidate();
      } else {
        return url === "/survey/reports/:idx" || url === "/survey/demographic";
      }
    } else if (currentPath === "/survey/open-questions" || currentPath === "/survey/done") {
      return true;
    } else {
      return false;
    }
  }


  validateDecision(decision) {
    if (decision.accept_type === "Yes with security deposit"){
      return decision.accept_type && decision.security_deposit;
    } else {
      return decision.accept_type;
    }
  }


  render() {
    let { loggedIn, currentUser } = this.props;
    return (
      <Fragment>
        {
          loggedIn ? 
          <HeaderContainer>
            <Column>
              <HeaderLink 
                className={this.isActive("/survey/demographic") ? "active" : "" } 
                onClick={this.handleClick.bind(this, "/survey/demographic", "/survey/demographic")} 
                href="/survey/demographic">
                Demographic Questions
              </HeaderLink>
              <HeaderLink 
                className={this.isActive("/survey/reports/:idx") ? "active" : "" } 
                onClick={this.handleClick.bind(this, "/survey/reports/1", "/survey/reports/:idx")} 
                href="/survey/reports/1">
                Experiment
              </HeaderLink>
              <HeaderLink 
                className={this.isActive("/survey/open-questions") ? "active" : "" } 
                onClick={this.handleClick.bind(this, "/survey/open-questions",  "/survey/open-questions")} 
                href="/survey/open-questions">
                Open-ended Questions
              </HeaderLink>
            </Column>

            <Column>
              Session ID: { currentUser.uuid } 
            </Column>
          </HeaderContainer> : null
        }
        <Gutter h={50} />
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    loggedIn: state.loggedIn,
    decisions: state.decisions
  }
}

export default withRouter(connect(mapStateToProps)(Header));