import React from 'react';
import { changeSessions, windowResize } from '../../actions';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import _ from 'lodash';
import FirstPage from './FirstPage';
import ConsentPage from './ConsentPage';
import IntroductionPage from './IntroductionPage';
import DemographicPage from './DemographicPage';
import TutorialPage from './TutorialPage';
import ExamplePage from './ExamplePage';
import DecisionPage from './DecisionPage';
import OpenQuestionsPage from './OpenQuestionsPage';
import DonePage from './DonePage';
import { Header } from '../../components';
import { Gutter } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';

const Fragment = React.Fragment;

const RequireAuth = ({ children }) => {
  if (!this.props.loggedIn) {
    return <Redirect to="/survey" />;
  }

  return children;
};

class Welcome extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler(); 
    this.loadSessionData();
  }

  async loadSessionData(){
    let url = `${API_URL}/api/sessions.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    
    const response = await fetch(url, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeSessions(data.logged_in, data.current_user, data.csrf_token));
    } else {
      let data = await response.json();
      return data;
    }

  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }

  renderWithAuthentication(children){
    let { sessionSynced, loggedIn } = this.props;

    if (!sessionSynced) {
      return (
        <Fragment>
          <Header />
          { children }
        </Fragment>
      )
    } else {
      return loggedIn ? 
      (
        <Fragment>
          <Header />
          { children }
        </Fragment>
      )
      : <Redirect to="/survey" />;
    }

  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/survey">
            <FirstPage />
          </Route>
          <Route exact path="/survey/consent">
            {
              this.renderWithAuthentication(<ConsentPage />)
            }
          </Route>
          <Route exact path="/survey/introduction">
            {
              this.renderWithAuthentication(<IntroductionPage />)
            }
          </Route>

          <Route exact path="/survey/demographic">
            {
              this.renderWithAuthentication(<DemographicPage />)
            }
          </Route>

          <Route exact path="/survey/tutorial">
            {
              this.renderWithAuthentication(<TutorialPage />)
            }            
          </Route>

          <Route exact path="/survey/example">
            {
              this.renderWithAuthentication(<ExamplePage />)
            }             
          </Route>
          
          <Route exact path="/survey/reports/:idx">
            {
              this.renderWithAuthentication(<DecisionPage />)
            }            
          </Route>

          <Route exact path="/survey/open-questions">
            {
              this.renderWithAuthentication(<OpenQuestionsPage />)
            } 
          </Route>

          <Route exact path="/survey/done">
            {
              this.renderWithAuthentication(<DonePage />)
            } 
          </Route>

        </Switch>

        
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    mapLoaded: state.mapLoaded,
    loggedIn: state.loggedIn,
    currentUser: state.currentUser,
    sessionSynced: state.sessionSynced
  }
}

export default withRouter(connect(mapStateToProps)(Welcome));