import React, { Component, useContext } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { constrain } from '../../utils';
import _ from 'lodash'
import { Container, Gutter } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';
import { DecisionSelect, ReportArea } from '../../components';
import { changeDecisions, changeUser } from '../../actions';
import Shepherd from 'shepherd.js';
const Fragment = React.Fragment;
const TIME_LIMIT = 30;

const NavigateArea = styled.div`
  display: flex;
  position: fixed;
  right: 30px;
  top: 80px;
  align-items: center;
  justify-content: space-between;
`;

const NavigateIndicator = styled.div`
  margin: 0 10px;
`;

const ReportAreaPseudo = styled.div`
  position:absolute; 
  left: 30px;
  top: 80px;
  width:calc(100vw - 250px);
  max-width: 1200px;
  height: calc(100vh - 320px);
`;

const DecisionArea = styled.div`
  border-top: 1px solid black;
  background-color: #FAF3E6;
  padding: 20px 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - 40px);
  height: 200px;
`;

const ValidateButton = styled.button`

`;

const ValidateArea = styled.div`
  display: flex;
  align-items: center;
`
const ValidateTimer = styled.div`
  margin-left: 10px;
  font-size: 0.7em;
`

class DecisionPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      initialized: false,
      tourComplete: false,
      timer: TIME_LIMIT
    }

    this.timerInterval = null;
  }
  componentDidMount(){

    this.initTour();
    if (this.props.csrfToken) {
      this.initializeReports();
    }
  }

  componentWillUnmount(){
    this.tour.cancel();
  }

  componentDidUpdate(prevProps){
    if (!prevProps.csrfToken && this.props.csrfToken) {
      this.initializeReports();
    }
    // console.log("params idx check", this.props.match.params.idx, prevProps.match.params.idx, this.props.match.params.idx !== prevProps.match.params.idx);

    if (this.props.match.params.idx !== prevProps.match.params.idx) {
      (document.scrollingElement || document.documentElement).scrollTop = 0;
      if (this.state.initialized) {
        let decision = this.props.decisions[this.props.match.params.idx - 1];
        console.log("decision result", decision, this.validateDecision(decision));
        if (!this.validateDecision(decision)) {
          this.setTimer();
        }
      }
      
    }
  }

  setTimer(){
    
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.timerInterval = null;
    }

    this.setState({
      timer: TIME_LIMIT
    });

    this.timerInterval = setInterval(() => {
      if (this.state.timer > 0) {
        this.setState({
          timer: this.state.timer - 1
        })
      } else {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  initTour(){
    this.tour = new Shepherd.Tour({
      useModalOverlay: true
    });
    
    this.tour.addStep({
      id: 'intro-step',
      text: 'This is the experiment page that you will be working on—it is comprised of 1) a tenant screening report on the main screen and 2) a decision interface on the bottom.',
      classes: 'intro-step',
      buttons: [
        {
          text: 'Cancel',
          action: this.cancelTour.bind(this)
        },
        {
          text: 'Next',
          action: this.tour.next
        }
      ]
    });
    this.tour.addStep({
      id: 'report-step',
      attachTo: {
        element: '.report-area-pseudo',
        on: 'right'
      },
      text: 'This is the tenant screening report you will review each page. It has 1) applicant information, 2) credit score, 3) criminal or eviction history if each report has.',
      classes: 'report-step',
      buttons: [
        {
          text: 'Cancel',
          action: this.cancelTour.bind(this)
        },
        {
          text: 'Prev',
          action: this.tour.back
        },
        {
          text: 'Next',
          action: this.tour.next
        }
      ]
    });

    this.tour.addStep({
      id: 'decision-step',
      text: 'Here is the decision panel. After reviewing the tenant screening report, you will accept or reject the tenant. There are three choices; you can choose 1) accepting the tenant with no security deposit ("Yes"), 2) accepting the tenants with security deposit ("Yes with security deposit") or 3) rejecting the tenant ("No").',
      attachTo: {
        element: '.decision-area',
        on: 'top'
      },
      classes: 'decision-step',
      buttons: [
        {
          text: 'Cancel',
          action: this.cancelTour.bind(this)
        },
        {
          text: 'Prev',
          action: this.tour.back
        },
        {
          text: 'Next',
          action: this.tour.next
        }
      ]
    });

    this.tour.addStep({
      id: 'navigate-step',
      text: 'There are 25 reports you should review for the HIT—you can go back to previous decisions for reviewing.',
      attachTo: {
        element: '.navigate-area',
        on: 'left'
      },
      classes: 'navigate-step',
      buttons: [
        {
          text: 'Prev',
          action: this.tour.back
        },
        {
          text: 'Done',
          action: this.completeTour.bind(this)
        }
      ]
    });
  }

  async postTutorialChecked(){

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };
    
    let formData = new FormData();    
    formData.append(`user[tutorial_checked]`, true);

    const response = await fetch(`/api/users.json`, { method: "put", body: formData, headers });

    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeUser(data.user));

    } else {
      let data = await response.json();
      
      return data;

    }

  }


  cancelTour(){

    this.setState({
      tourComplete: true
    });
    this.postTutorialChecked();
    this.tour.cancel();
  }
  completeTour(){
    this.setState({
      tourComplete: true
    });
    this.postTutorialChecked();
    this.tour.next();
  }
  
  async initializeReports(){
    let url = `${API_URL}/api/users/initialize_reports.json`;

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    const response = await fetch(url, { method: "post", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeDecisions(data.decisions));

      this.setState({
        initialized: true
      });

      let currentIdx = Number(this.props.match.params.idx);
      if (currentIdx === 1 && !this.props.currentUser.tutorial_checked) {
        this.tour.start();
      } else {
        this.setState({
          tourComplete: true
        })
      }


      let decision = data.decisions[this.props.match.params.idx - 1];
      if (this.validateDecision(decision)) {
        this.setState({
          timer: 0
        })
      } else {
        this.setTimer();
      }

    } else {
      let data = await response.json();
      return data;

    }

  }

  handleNextClick(e){
    let currentIdx = Number(this.props.match.params.idx);

    if (currentIdx) {

      if (currentIdx >= 25) {
        this.props.history.push(`/survey/open-questions`);
      } else {
        let nextIdx = constrain(currentIdx + 1, 1, 25);
        this.props.history.push(`/survey/reports/${nextIdx}`);
      }
    }
  }

  handlePrevClick(e){
    let currentIdx = Number(this.props.match.params.idx);
    if (!this.validateDecision(this.props.decisions[currentIdx - 1])) {

      if (confirm("Are you sure? You need to spend additional 60 seconds when you are back here without making a decision on the current report.")) {

        if (currentIdx) {
          if (currentIdx <= 1) {
            this.props.history.push(`/survey/tutorial`);
          } else {   
            let prevIdx = constrain(currentIdx - 1, 1, 25);
            this.props.history.push(`/survey/reports/${prevIdx}`);
          }
        }
      }

    } else {
      
      if (currentIdx) {
        if (currentIdx <= 1) {
          this.props.history.push(`/survey/tutorial`);
        } else {   
          let prevIdx = constrain(currentIdx - 1, 1, 25);
          this.props.history.push(`/survey/reports/${prevIdx}`);
        }
      }
    }

  }

  validateDecision(decision) {
    console.log(decision.accept_type, decision.security_deposit);
    if (decision.accept_type === "Yes with security deposit"){
      return decision.accept_type && decision.security_deposit;
    } else {
      return decision.accept_type;
    }
  }

  determineNextAvailable(){
    let { initialized, timer } = this.state;
    let { loggedIn, decisions } = this.props;
    let currentIdx = Number(this.props.match.params.idx);

    if (timer > 0) {
      return false;
    }

    let nextAvailable = false;
    
    
    let decision;
    if (initialized) {

      if (currentIdx === 25) {
        nextAvailable = true;
        _.each(decisions, decision => {
          if (!this.validateDecision(decision)){
            nextAvailable = false;
          }   
        })

      } else {
        decision = decisions[currentIdx - 1];
        nextAvailable = this.validateDecision(decision);    
      }
    }


    return nextAvailable;
  }
  
  render() {
    let { loggedIn, decisions } = this.props;
    let { initialized, tourComplete } = this.state;
    let currentIdx = Number(this.props.match.params.idx);
    let decision;

    if (initialized) {
      decision = decisions[currentIdx - 1];
    }

    return (
      <Container>
        {
          loggedIn ? 
          <Fragment>
            {
              initialized && decision? 
              <Fragment>
                {
                  !tourComplete ? 
                  <ReportAreaPseudo className="report-area-pseudo" /> : null
                }
                <ReportArea decision={decision} />
                <NavigateArea className="navigate-area">

                  <button onClick={this.handlePrevClick.bind(this)}>
                    &lt;
                  </button> 

                  <NavigateIndicator>
                    {currentIdx} of 25
                  </NavigateIndicator>

                  <button disabled={!this.determineNextAvailable()} onClick={this.handleNextClick.bind(this)}>
                    &gt;
                  </button> 
                </NavigateArea>

                <DecisionArea className="decision-area">
                  <p>
                    Would you rent your property to this tenant?
                  </p>
                  <DecisionSelect decision={decision} />
                  <Gutter h={10} />
                  
                  <ValidateArea>
                    <ValidateButton disabled={!this.determineNextAvailable()} onClick={this.handleNextClick.bind(this)}>
                      Next &gt;
                    </ValidateButton>
                    {
                      this.state.timer > 0 ?
                      <ValidateTimer>
                        {this.state.timer}s to go to next
                      </ValidateTimer> : null
                    }
                  </ValidateArea>
                </DecisionArea>
              </Fragment> : 
              <Fragment>
                Loading...
              </Fragment>
            }
           
          </Fragment> : null
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    csrfToken: state.csrfToken,
    decisions: state.decisions,
    currentUser: state.currentUser
  }
}

export default withRouter(connect(mapStateToProps)(DecisionPage));