import React, { Component } from 'react'
import dashboardAdminReducer from './reducers/dashboardAdminReducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import DashboardAdmin from './pages/dashboard_admin/DashboardAdmin' ;
import { BrowserRouter as Router } from "react-router-dom";
import "./stylesheets/reset.scss";
import "./stylesheets/fonts.scss";
import "./stylesheets/application.scss";

export default class SurveyApp extends Component {
  componentDidMount() {

  }
  render() {

    return (
      <Provider store={createStore(dashboardAdminReducer)}>
        <Router>
          <DashboardAdmin />
        </Router>
      </Provider>
    )
  }
}