export const DEFAULT_WELCOME_STATE = {
  windowWidth: 1024,
  windowHeight: 768,
  mapLoaded: false
};

export const DEFAULT_SURVEY_WELCOME_STATE = {
  windowWidth: 1024,
  windowHeight: 768,
  currentUser: null,
  loggedIn: false,
  sessionSynced: false,
  csrfToken: null,
  decisions: []
}

export const DEFAULT_FORKINGROOM_STATE = {
  windowWidth: 1024,
  windowHeight: 768,
  mapLoaded: false,
  forkingroomDecision: null,
  forkingroomCurrentCity: 0,
  forkingroomCities: []
};
export const API_URL = process.env.NODE_ENV === "production" ? `https://tenant-screening.wonyoung.so` : "http://localhost:3000";

export const isCurrentlyRentingOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]


export const numberOfUnitsOptions = [
  { label: "1 unit", value: "1 unit" },
  { label: "2-4 units", value: "2-4 units" },
  { label: "4-10 units", value: "4-10 units" },
  { label: "10-50 units", value: "10-50 units" },
  { label: "More than 50 units", value: "More than 50 units" }
];

export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "Non-binary" },
  { label: "Other", value: "Other" },
  { label: "Prefer not to say", value: "Prefer not to say" },
];


export const ethnicityOptions = [
  { label: "White", value: "White" },
  { label: "Black", value: "Black" },
  { label: "Hispanic", value: "Hispanic" },
  { label: "Asian", value: "Asian" },
  { label: "Native American", value: "Native American" },
  { label: "Hawaiian / Pacific Islander", value: "Hawaiian / Pacific Islander" },
  { label: "Other", value: "Other" }
];

export const incomeOptions = [
  { label: "Under $40,000", value: "Under $40,000" },
  { label: "$40,001-$60,000", value: "$40,001-$60,000" },
  { label: "$60,001-$80,000", value: "$60,001-$80,000" },
  { label: "$80,001-$100,000", value: "$80,001-$100,000" },
  { label: "$100,001-$120,000", value: "$100,001-$120,000" },
  { label: "More than $120,000", value: "More than $120,000" },
];
