import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';
import { tablize, round } from '../utils';
import { changeDecisions, changeUser } from '../actions';
import _ from 'lodash';
import ReactSlider from 'react-slider';


const Fragment = React.Fragment;

const SelectButton = styled.button`
  display:block;
  width: 250px;
  text-align: left;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #222;
    color: white;
  }
  
  &.selected {
    background-color: black;
    color: white;
  }
`;

const options = [
  { value: "Yes", label: "Yes" },
  { value: "Yes with security deposit", label: "Yes with security deposit" },
  { value: "No", label: "No" }
]

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  margin-right: 25px;
`;

const SliderArea = styled.div`
  width: 200px;
  height: 25px;
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
`;
const StyledTrack = styled.div`
  top: 50%;
  height:1px;
  bottom: 0;
  background: ${props => props.index === 2 ? '#000' : props.index === 1 ? '#000' : '#000'};
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

const StyledThumb = styled.div`
  height: 15px;
  width: 15px;
  top: 5px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const ThumbLabel = styled.div`
  top: -30px;
  left: -32px;
  font-size:12px;
  line-height: 1.1;
  width: 80px;
  text-align: center;
  position: relative;
`;

const Thumb = (props, state) => 
  <StyledThumb {...props}>
    <ThumbLabel>
      {round(state.valueNow / 100, 1)} month<br/>
      rent
    </ThumbLabel>
  </StyledThumb>;

class DecisionSelect extends Component {
  constructor(props){
    super(props);
    this.state = {
      buttonDisabled: false
    }
  }
  async postDecision(values){
    let { decision, decisions, idx } = this.props;
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };
    
    let formData = new FormData();    
    _.each(values, (v, k) => {
      formData.append(`decision[${k}]`, v);
    })
    const response = await fetch(`/api/decisions/${decision.id}.json`, { method: "put", body: formData, headers });

    if (response.ok) {
      let data = await response.json();
      let newDecisions = [...decisions ];

      let idx = _.findIndex(newDecisions, d => d.id === data.decision.id);
      newDecisions[idx] = data.decision;
      this.props.dispatch(changeDecisions(newDecisions));

      this.setState({
        buttonDisabled: false
      })

    } else {
      let data = await response.json();
      console.log(data);
      this.setState({
        buttonDisabled: false
      })
      return data;

    }

  }

  handleClick(value){
    if (!this.state.buttonDisabled) {
      this.setState({
        buttonDisabled: true
      })
      this.postDecision({
        accept_type: value
      });
    }

  }

  handleSliderChange(e){
    let value = round(e / 100, 1);
    this.postDecision({
      security_deposit: value
    });
  }

  render() {
    let { idx, decision } = this.props;

    return (
      <SelectContainer>
        <Column>
          {
            _.map(options, (option, idx) => {
              let selected = false;
              if (!_.isNull(decision.accept_type)) {
                selected = decision.accept_type === option.value;
              }
              
              return (
                <SelectButton key={tablize(option.value)} className={selected ? "selected" : ""} style={{ borderBottom: idx === options.length - 1 ? "1px solid black" : "none"}} onClick={this.handleClick.bind(this, option.value)}>
                  {option.label}
                </SelectButton>
              )
            })
          }
        </Column>
        <Column>
          {
            decision.accept_type === "Yes with security deposit" ? 
            <SliderArea>
              <StyledSlider
                onAfterChange={this.handleSliderChange.bind(this)}
                min={0}
                max={300}
                value={decision.security_deposit * 100}
                marks={[0, 50, 100, 150, 200, 250, 300]}
                step={1}
                renderTrack={Track}
                renderThumb={Thumb}
              />
            </SliderArea> : null
          }
        </Column>
      </SelectContainer>
    )
  }
}

let mapStateToProps = state => {
  return {
    csrfToken: state.csrfToken,
    decisions: state.decisions
  }
}

export default connect(mapStateToProps)(DecisionSelect);