import { DEFAULT_SURVEY_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';

export default function surveyReducer(state = DEFAULT_SURVEY_WELCOME_STATE, action){
  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      };
    case "CHANGE_MAP_LOADED":
      return {
        ...state,
        mapLoaded: action.payload.mapLoaded
      };
    case 'CHANGE_SESSIONS':
      return {
        ...state,
        sessionSynced: true,
        loggedIn: action.payload.loggedIn,
        currentUser: action.payload.currentUser,
        csrfToken: action.payload.csrfToken
      };
    case 'CHANGE_DECISIONS':
      return {
        ...state,
        decisions: action.payload.decisions
      };
    case 'CHANGE_USER':
      return {
        ...state,
        currentUser: action.payload.currentUser
      };
    default: 
      return {
        ...state
      };
  }

};