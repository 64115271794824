import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TitleArea, Gutter, FlexSpaceWrapperCenter } from '../stylesheets/components';
import styled from 'styled-components'
import { CreditScoreArea, EvictionRecordArea, CriminalRecordArea } from './';
import link_icon from "../assets/link_icon.svg";
const RecordArea = styled.div`
  border-bottom: 1px solid black;
  padding: 15px 0;
  display: flex;
  align-items: center;

  &.active {

    cursor:pointer;
    transition: 0.4s opacity;
    &:hover {
      opacity: 0.4;
    }
  }
`
const RecordTitle = styled.div`
  margin-right: 20px;
  width: 200px;
`
const RecordStatus = styled.div``

class Type2RenderRecords extends Component {
  constructor(props){
    super(props);
    this.state = {
      showMode: null
    }
  }

  handleClick(showMode){
    this.setState({
      showMode: showMode
    })
  }

  componentDidUpdate(prevProps){
    if (prevProps.decision.id !== this.props.decision.id){
      this.setState({
        showMode: null
      });
    }
  }
  render() {
    let { currentUser, decision } = this.props;
    return (
      <>
        <TitleArea>
          Reports
        </TitleArea>
        <div>
          {
            this.state.showMode === "credit" ?
            <>
            <Gutter h={20} />
              <button onClick={this.handleClick.bind(this, null)}>
                &lt; back
              </button>
              <Gutter h={20} />
              <CreditScoreArea decision={decision} />
            </>
            : null
          }
          {
            this.state.showMode === "eviction" ?
            <>
            <Gutter h={20} />
              <button onClick={this.handleClick.bind(this, null)}>
                &lt; back
              </button>
              <Gutter h={20} />
              <EvictionRecordArea decision={decision} />
            </>
            : null
          }
          {
            this.state.showMode === "criminal" ?
            <>
            <Gutter h={20} />
              <button onClick={this.handleClick.bind(this, null)}>
                &lt; back
              </button>
              <Gutter h={20} />
              <CriminalRecordArea decision={decision} />
            </>
            : null
          }
          {
            _.isNull(this.state.showMode) ? 
            <>
              <RecordArea  className="active" onClick={this.handleClick.bind(this, "credit")}>
                <RecordTitle>
                  Credit Report
                </RecordTitle>
                <RecordStatus>
                  { decision.credit_score } &nbsp;<img src={link_icon} alt="link to credit record" />              
                </RecordStatus>
              </RecordArea>

              <RecordArea onClick={this.handleClick.bind(this, "eviction")} className={decision.report_type === "Eviction" ? "active" : ""}>
                <RecordTitle>
                  Eviction Record
                </RecordTitle>
                <RecordStatus>
                  { decision.report_type === "Eviction" ? 
                    <>
                      1 Record Found &nbsp;<img src={link_icon} alt="link to eviction record" />                  
                    </>
                    : "No Record Found" }
                </RecordStatus>
              </RecordArea>


              <RecordArea onClick={this.handleClick.bind(this, "criminal")} className={decision.report_type === "Criminal" ? "active" : ""}>
                <RecordTitle>
                  Criminal Record
                </RecordTitle>
                <RecordStatus>
                  { decision.report_type === "Criminal" ?
                  <>
                    1 Record Found &nbsp;<img src={link_icon} alt="link to criminal record" />                  
                  </> : "No Record Found" }
                </RecordStatus>
              </RecordArea>
            </> : null 
          }
          
        </div>
      </>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(Type2RenderRecords);