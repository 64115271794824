import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../../utils';
import _ from 'lodash';
import { Gutter } from '../../stylesheets/components';
import MapContainer from '../../components/MapContainer';
import { changeForkingroomCities, changeForkingroomCurrentCity} from '../../actions';
import { API_URL } from '../../constants/defaults';

const Fragment = React.Fragment;
const MapWrapper = styled.div`
  position: relative;
  width: 33.33333%;
`;

const Container = styled.div`
  display: flex;
`;
const MapButton = styled.button`
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  z-index:5;
`

class ForkingroomMapPage extends Component {
  constructor(props){
    super(props);
    this.intervalId = null;
  }
  componentDidMount(){
    setTimeout(() => {
      document.location.reload();
    }, 1800000);
  }
  componentWillUnmount(){
    clearInterval(this.intervalId);
  }
  componentDidUpdate(prevProps){
    if (!prevProps.mapLoaded && this.props.mapLoaded) {
      this.loadData();
    }
  }
  
  async loadData(){
    let url = `${API_URL}/api/firstpage/all.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(url, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeForkingroomCities(data.cities));
      this.initIntervalChange();
    } else {
      let data = await response.json();
      return data;

    }
  }

  initIntervalChange(){
    
    this.intervalId = setInterval(() => {
      let { forkingroomCities } = this.props;
      this.props.dispatch(changeForkingroomCurrentCity(
        (this.props.forkingroomCurrentCity + 1) % 
        forkingroomCities.length
      ));
    }, 20000);
    
  }

  render() {
    return (
      <Container>
        <MapWrapper>
          <MapButton>
            Population % of African Americans
          </MapButton>
          <MapContainer mode="blackpop" />
        </MapWrapper>

        <MapWrapper>
          <MapButton>
            Population % of Latino Americans
          </MapButton>
          <MapContainer mode="latinopop" />
        </MapWrapper>

        <MapWrapper>
          <MapButton>
            Eviction Fillings after COVID-19
          </MapButton>
          <MapContainer mode="evictions" />
        </MapWrapper>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    mapLoaded: state.mapLoaded,
    forkingroomCities: state.forkingroomCities,
    forkingroomCurrentCity: state.forkingroomCurrentCity
  }
}

export default withRouter(connect(mapStateToProps)(ForkingroomMapPage));