import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TitleArea, FlexSpaceBetweenWrapperWrap, RecordColumn, RedactedArea } from '../stylesheets/components';
import styled from 'styled-components';
import moment from 'moment';

const Fragment = React.Fragment;

class CriminalRecordArea extends Component {
  renderCriminalRecordType1(){

    let { decision, currentUser } = this.props;

    return (
      <FlexSpaceBetweenWrapperWrap>

        <RecordColumn>
          Name Searched<br/>
          <RedactedArea length={ decision.name} />
        </RecordColumn>

        <RecordColumn>
          SSN Searched<br/>
          { decision.ssn}
        </RecordColumn>
        <RecordColumn>
          Case Number<br/>
          { decision.criminal_case_number}
        </RecordColumn>
        <RecordColumn>
          Disposition Date<br/>
        </RecordColumn>
        <RecordColumn>
          Disposition
        </RecordColumn>
        <RecordColumn>
          Jurisdiction<br/>
          { decision.criminal_jurisdiction }
        </RecordColumn>
      </FlexSpaceBetweenWrapperWrap>
    )
  }

  renderCriminalRecord(){

    let { decision } = this.props;

    return (
      <FlexSpaceBetweenWrapperWrap>
        <RecordColumn>
          Case Number<br/>
          { decision.criminal_case_number}
        </RecordColumn>
        <RecordColumn>
          Case Type<br/>
          { decision.criminal_case_type}
        </RecordColumn>

        <RecordColumn>
          Charge Date<br/>
          { moment(decision.criminal_charge_date).format("MM-DD-Y")}
        </RecordColumn>

        <RecordColumn>
          Disposition Date<br/>
          {
            decision.criminal_disposition_date ? 
            moment(decision.criminal_disposition_date).format("MM-DD-Y") : null
          }
        </RecordColumn>
        <RecordColumn>
          Disposition<br/>
          {
            decision.criminal_disposition_result ? 
            decision.criminal_disposition_result : null
          }
        </RecordColumn>

        <RecordColumn>
          Jurisdiction<br/>
          {
            decision.criminal_jurisdiction ? 
            decision.criminal_jurisdiction : null
          }
        </RecordColumn>

        <RecordColumn>
          Offense Date<br/>
          {
            decision.criminal_offense_date ? 
            moment(decision.criminal_offense_date).format("MM-DD-Y") : null
          }
        </RecordColumn>

        <RecordColumn>
          Degree<br/>
          {
            decision.criminal_offense_level_and_degree ? 
            decision.criminal_offense_level_and_degree : null
          }
        </RecordColumn>
        <RecordColumn>
          Charge<br/>
          {
            decision.criminal_types_of_charge ? 
            decision.criminal_types_of_charge : null
          }
        </RecordColumn>
      </FlexSpaceBetweenWrapperWrap>
    )

  }

  render() {
    let { decision, currentUser } = this.props;
    return (
      <Fragment>

        <TitleArea>
          Criminal Records {
            decision.report_type === "Criminal" ? 
              <>1 of 1</>
              : null
          }
        </TitleArea>
        {
          decision.report_type === "Criminal" ?
          <Fragment>
            {
              currentUser.view_mode === "Type 1" ?
              this.renderCriminalRecordType1()
              :
              this.renderCriminalRecord()
            }
          </Fragment> : 
          <Fragment>
            No Criminal Record Found.
          </Fragment>
        } 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(CriminalRecordArea);