import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../utils';
import _ from 'lodash';
import { Gutter } from '../stylesheets/components';
import MapContainer from '../components/MapContainer';
import { API_URL } from '../constants/defaults';

const Fragment = React.Fragment;
const MapWrapper = styled.div`
  position: relative;

`;

const MapButton = styled.button`
  position: absolute;
  left: 20px;
  top: 20px;
  z-index:5;
`

class FirstPage extends Component {
  constructor(props){
    super(props);

    this.state = {
      mode: "blackpop"
    };
  }
  componentDidMount(){
    this.loadData();
  }
  
  async loadData(){
    let url = `${API_URL}/api/firstpage/all.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(url, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      
    } else {
      let data = await response.json();
      return data;

    }
  }

  handleClick(e){
    this.setState({
      mode: this.state.mode === "blackpop" ? "eviction" : "blackpop"
    });
  }

  render() {
    return (
      <Fragment>
        <MapWrapper>

          <MapButton onClick={this.handleClick.bind(this)}>
            { this.state.mode === "blackpop" ? "Population % of African Americans" : "Eviction Filings After COVID-19" }
          </MapButton>
          <MapContainer mode={this.state.mode} />
        </MapWrapper>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth
  }
}

export default withRouter(connect(mapStateToProps)(FirstPage));