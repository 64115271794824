import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CreditScoreArea, CriminalRecordArea, EvictionRecordArea, Type2RenderRecords } from '../components';
import { TitleArea, Gutter, SectionAreaColumn, RedactedArea } from '../stylesheets/components';
import low_risk_icon from "../assets/low_risk_icon.svg";
import mid_risk_icon from "../assets/mid_risk_icon.svg";
import high_risk_icon from "../assets/high_risk_icon.svg";

const RISK_ICON = {
  "LOW - CLEAN": low_risk_icon,
  MID: mid_risk_icon,
  HIGH: high_risk_icon
}
const Paper = styled.div`
  padding: 30px;
  background-color: white;
  width:calc(100vw - 310px);
  max-width: calc(1200px - 60px);
`;

const SectionAreaInbtwn = styled.div`
  display: flex;
  justify-content: space-between;
  height: 150px;
`
const Column = styled.div`
  width: 33.33%;
  line-height: 1.3;
  margin-bottom: 40px;
`

const Type1Desc = styled.div`
  text-align: center;
  padding: 20px 0;
  line-height: 1.4;
  margin-bottom: 40px;
`;

const HalfColumn = styled.div`
  width: 33.3%;
  line-height: 1.3;
  margin: 40px 0;
`;

const Indicator = styled.div`
  border-radius: 15px;
  background-color: #90D575;
  padding: 7px 10px;
  color:white;
  font-size:0.8em;
  line-height:1.1;
`;

class ReportArea extends Component {
  renderType1Desc(){
    
    let { decision, currentUser } = this.props;
    if (currentUser.view_mode === "Type 1" && decision.report_type !== "Clean"){
      return (
        <Type1Desc>
          Disqualifying record(s) found.<br/>
          Please review these records to your applicant and proceed with your screening policies.
        </Type1Desc>
      )
    } else {
      return null;
    }
  }

  renderType2(){

    let { decision, currentUser } = this.props;
    if (currentUser.view_mode === "Type 2"){
      return (
        <>
          <TitleArea>
            AccurateRent™️ Risk Decision
          </TitleArea>
          <SectionAreaColumn>
            <HalfColumn>
              <img src={RISK_ICON[decision.score]} />
            </HalfColumn>
            <HalfColumn>
              Key Factors<br/>
              Credit Score: { decision.credit_score } <br />
              {
                decision.report_type === "Eviction" ? 
                <>1 Eviction Record Found<br/></> : null
              }
              {
                decision.report_type === "Criminal" ? 
                <>1 Criminal Record Found<br/></> : null
              }

            </HalfColumn>
          </SectionAreaColumn>

          <Gutter h={50} />
        </>
      )
    } else {
      return null;
    }

  }

  render() {
    let { decision, currentUser } = this.props;
    return (
      <Paper>
        <SectionAreaInbtwn>
          <Column>
            Tenant Screening Report<br/>
            Report Number: {decision.report_number}
          </Column>
          <Column>
            CoreTenant.com<br/>
            723 Arthur River<br/>
            Medford, MA 69157
          </Column>
        </SectionAreaInbtwn>

        <TitleArea>
          Applicant Information
        </TitleArea>
        <SectionAreaColumn>
          <Column>
            Name<br/>
            <RedactedArea length={decision.name} />
            
          </Column>
          <Column>
            SSN<br/>
            { decision.ssn }
          </Column>

          <Column>
            Phone<br/>
            { decision.phone }
          </Column>
        </SectionAreaColumn>

        <SectionAreaColumn>

          <Column>
            Email<br/>
            <RedactedArea length={ decision.email } />
          </Column>
        </SectionAreaColumn>
        
        <Gutter h={50} />

        {
          this.renderType2()
        }

        {
          currentUser.view_mode === "Type 2" ?
          <Type2RenderRecords decision={decision} /> :
          <>

            <CreditScoreArea decision={decision} />

            <Gutter h={100} />
            { this.renderType1Desc() }

            <EvictionRecordArea decision={decision} />

            <Gutter h={100} />

            <CriminalRecordArea decision={decision} />

          </>
        }

        <Gutter h={300} />        
      </Paper>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}
export default connect(mapStateToProps)(ReportArea);