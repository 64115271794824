import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../../utils';
import _ from 'lodash';
import { Container, Gutter, MiddleAligner } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';

const Fragment = React.Fragment;


class IntroductionPage extends Component {
  handleClick(e){

    this.props.history.push("/survey/demographic");
  }


  render() {
    let { currentUser, loggedIn } = this.props;
    return (
      <Container>
        <MiddleAligner>
          <div>
            {
              loggedIn ? 
              <Fragment>
                <h1>
                  This survey is to see homeowner and landlords' tenancy decision making process after they read tenant screening reports.
                </h1>
                <p>
                  This survey is to test the following:<br/><br/>

                  First, you provide basic demographic information. Second, you will be shown tenant screening reports of 25 tenants.  you choose whether or not you accept each tenant, and you can add some security deposit if you need. Lastly, you will be asked how you accept or reject tenants from the reports at the end of the survey.
                </p>
                <Gutter h={20} />
                <button onClick={this.handleClick.bind(this)}>
                  Okay &gt;
                </button>
              </Fragment>: null
            }
          </div>
        </MiddleAligner>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    currentUser: state.currentUser
  }
}

export default withRouter(connect(mapStateToProps)(IntroductionPage));