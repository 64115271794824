import React from 'react';
import { changeSessions, windowResize } from '../../actions';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Header } from '../../components';
import { Gutter } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';
import ForkingroomMapPage from './ForkingroomMapPage';
import ForkingroomSurveyPage from './ForkingroomSurveyPage';

const Fragment = React.Fragment;


class ForkingroomWelcome extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler(); 
    // this.loadSessionData();
  }

  // async loadSessionData(){
  //   let url = `${API_URL}/api/sessions.json`;

  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Accept': 'application/json',
  //     'X-Requested-With': 'XMLHttpRequest'
  //   }
    
  //   const response = await fetch(url, { method: "get", headers });
  //   if (response.ok) {
  //     let data = await response.json();
  //     this.props.dispatch(changeSessions(data.logged_in, data.current_user, data.csrf_token));
  //   } else {
  //     let data = await response.json();
  //     return data;
  //   }

  // }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }


  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/forkingroom/map">
            <ForkingroomMapPage />
          </Route>
          <Route exact path="/forkingroom/survey">
            <ForkingroomSurveyPage />
          </Route>

        </Switch>

        
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    mapLoaded: state.mapLoaded,
    loggedIn: state.loggedIn,
    currentUser: state.currentUser,
    sessionSynced: state.sessionSynced
  }
}

export default withRouter(connect(mapStateToProps)(ForkingroomWelcome));