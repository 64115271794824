import { DEFAULT_WELCOME_STATE } from '../constants/defaults';
import _ from 'lodash';

export default function welcomeReducer(state = DEFAULT_WELCOME_STATE, action){
  switch(action.type) {
    case "WINDOW_RESIZE":
      return {
        ...state,
        windowWidth: action.payload.windowWidth,
        windowHeight: action.payload.windowHeight
      };
    case "CHANGE_MAP_LOADED":
      return {
        ...state,
        mapLoaded: action.payload.mapLoaded
      };
    default: 
      return {
        ...state
      };
  }

};