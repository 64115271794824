import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../../utils';
import _ from 'lodash';
import { DemographicSelect } from '../../components';
import { Container, Gutter, MiddleAligner } from '../../stylesheets/components';
import { isCurrentlyRentingOptions, numberOfUnitsOptions, incomeOptions, ethnicityOptions, genderOptions } from '../../constants/defaults';

const Fragment = React.Fragment;


class DemographicPage extends Component {
  constructor(props){
    super(props);

  }

  validateAll(){
    let { currentUser } = this.props;

    if (currentUser.is_currently_renting === true) {

      return currentUser.is_currently_renting === true && !_.isNull(currentUser.number_of_unit) && !_.isNull(currentUser.gender) && !_.isNull(currentUser.ethnicity) && !_.isNull(currentUser.income);
    } else {

      return !_.isNull(currentUser.is_currently_renting) && !_.isNull(currentUser.gender) && !_.isNull(currentUser.ethnicity) && !_.isNull(currentUser.income);
    }

  }

  handleClick(e){
    if (this.validateAll()) {
      this.props.history.push("/survey/tutorial");
    }
  }


  render() {
    let { loggedIn, currentUser } = this.props;
    return (

      <Container>
        {
          loggedIn ?
          <div>
            <h1>
              Enter Demographic Information
            </h1>
            {/* <p>
              Are you currently renting your properties?
            </p> */}
            {/* <DemographicSelect 
              variableName="is_currently_renting" 
              options={isCurrentlyRentingOptions} /> */}
            <Gutter h={20} />

            {
              currentUser.is_currently_renting ? 
              <Fragment>
                <p>
                  How many units do you have for renting?
                </p>
                <DemographicSelect 
                  variableName="number_of_unit" 
                  options={numberOfUnitsOptions} />
                <Gutter h={20} />
              </Fragment> : null
            }

            <p>
              What is your gender?
            </p>
            <DemographicSelect 
              variableName="gender" 
              options={genderOptions} />
            <Gutter h={20} />


            <p>
              Can you tell us your race/ethnicity?
            </p>
            <DemographicSelect 
              variableName="ethnicity" 
              options={ethnicityOptions} />
            <Gutter h={20} />

            <p>
              Can you tell us your income level?
            </p>
            <DemographicSelect 
              variableName="income" 
              options={incomeOptions} />
            <Gutter h={20} />
            <button disabled={!this.validateAll()} onClick={this.handleClick.bind(this)}>
              Next &gt;
            </button>
          </div> : null
        }
        
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    currentUser: state.currentUser
  }
}

export default withRouter(connect(mapStateToProps)(DemographicPage));