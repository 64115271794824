import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TitleArea, SectionAreaColumn, FlexSpaceWrapperCenter, FlexSpaceBetweenWrapper } from '../stylesheets/components';
import styled from 'styled-components'
import score_bar from '../assets/score_bar.svg'
import indicator from '../assets/indicator.svg'
import { map_range } from '../utils';

const Fragment = React.Fragment;

const Column = styled.div`
  width: calc(50% - 20px);
  line-height:1.4;
`
const BigNum = styled.div`
  font-size: 3.0em;
  line-height:1.1;
`

const Indicator = styled.div`
  border-radius: 15px;
  background-color: #90D575;
  padding: 7px 10px;
  color:white;
  font-size:0.8em;
  line-height:1.1;
`;
const BarArea = styled.div`
  position: relative;
  padding-top: 20px;
`
const IndicatorImg = styled.img`
  position: absolute;
  left: 60%;
  top: 0;
`

class CreditScoreArea extends Component {
  render() {
    let { decision } = this.props;
    let leftIndicator = map_range(decision.credit_score, 620, 660, 66, 75.5);
    
    return (
      <Fragment> 
        <TitleArea>
          Credit Score
        </TitleArea>

        <FlexSpaceBetweenWrapper>
          <Column>
            <FlexSpaceWrapperCenter>
              <BigNum>
                { decision.credit_score}
              </BigNum>
              <Indicator>
                Fair Score
              </Indicator>
            </FlexSpaceWrapperCenter>
            <BarArea>
              <IndicatorImg src={indicator} alt="indicator" style={{left: `${leftIndicator}%`}}/>
              <img src={score_bar} alt="score bar" style={ {width: "100%"} } />
            </BarArea>
          </Column>
          <Column>
            Score Factors <br/>
            No derogatory financial records are found.
          </Column>
        </FlexSpaceBetweenWrapper>
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(CreditScoreArea);