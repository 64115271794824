import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../../utils';
import _ from 'lodash';
import { changeSessions } from '../../actions';
import { Container, MiddleAligner } from '../../stylesheets/components';
const Fragment = React.Fragment;


class FirstPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      buttonDisabled: false
    }
  }
  async createNewUser(){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };
    const response = await fetch(`/api/users.json`, { method: "post", body: {}, headers });

    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeSessions(data.logged_in, data.current_user, data.csrf_token));
      this.props.history.push("/survey/consent");

    } else {
      let data = await response.json();
      console.log(data);

      this.setState({
        buttonDisabled: false
      });
      return data;

    }

  }

  handleClick(e){
    let { sessionSynced, loggedIn } = this.props;
    let { buttonDisabled } = this.state;

    if (!buttonDisabled && sessionSynced) {
      this.setState({
        buttonDisabled: true
      });

      if (loggedIn) {
        this.props.history.push("/survey/consent");
      } else {
        this.createNewUser();
      }

    }
  }

  render() {
    return (
      <Container>
        <MiddleAligner>
          <div>
            <h1>
              Are you a landlord currently renting your property?
            </h1>
            <button onClick={this.handleClick.bind(this)}>
              Yes &gt;
            </button>
            <p>
              This survey is eligible only if you are.
            </p>
          </div>          
        </MiddleAligner>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    csrfToken: state.csrfToken,
    loggedIn: state.loggedIn,
    sessionSynced: state.sessionSynced
  }
}

export default withRouter(connect(mapStateToProps)(FirstPage));