import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Gutter, Container, MiddleAligner } from '../../stylesheets/components';
const Fragment = React.Fragment;
const LongButton = styled.button`
  width: inherit;
`

const ButtonArea = styled.div`
  button {
    display: block;
    text-align: left;
  }
`;

class TutorialPage extends Component {
  constructor(props){
    super(props);

  }
  componentDidMount(){
    this.loadData();
  }
  
  async loadData(){
    // let url = `${API_URL}/api/firstpage/all.json`;

    // const headers = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'X-Requested-With': 'XMLHttpRequest'
    // }
    // // this.props.dispatch(changeAnimationStatus("stop"));
    // // this.props.dispatch(changeProgress(true));
    // const response = await fetch(url, { method: "get", headers });
    // if (response.ok) {
    //   let data = await response.json();
      
    // } else {
    //   let data = await response.json();
    //   return data;

    // }

  }

  handleExampleClick(e){ 
    this.props.history.push("/survey/example");
  }

  handleSurveyClick(e){
    this.props.history.push("/survey/reports/1");
  }

  render() {
    let { loggedIn } = this.props;
    return (
      <Container>
        <MiddleAligner>
          {
            loggedIn ? 
            <div>
              <h1>
                How to make decisions using tenant screening reports
              </h1>
              <p>
                This section introduces how to make decisions after seeing tenant screening reports in this experiment.
              </p>
              <p>
                What is a tenant screening report?<br/>
                Tenant screening services utilize 1) credit score, 2) crime records, 3) eviction records and it produces reports that inform a landlord’s willingness to rent to a tenant. 
              </p>  
              <p>
                After you click “Start Experiment” below, you will be shown a tenant screening report and an interface that you will use for accepting or rejecting a tenant. <u>You have to spend at least 1 minute per report when decide.</u> We will guide you through in the next page.
              </p>
              <Gutter h={20} />
              <ButtonArea>
                <button onClick={this.handleSurveyClick.bind(this)}>
                  I read all the description. Start Experiment &gt;
                </button> 
              </ButtonArea>
            </div>: null 
          }          
        </MiddleAligner>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn
  }
}

export default withRouter(connect(mapStateToProps)(TutorialPage));