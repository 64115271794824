import styled from 'styled-components';
import mixins from './mixins';
import { Link } from 'react-router-dom';
import media from "./media";

const FlexSpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RecordColumn = styled.div`
  width: 33.33%;
  line-height: 1.3;
  margin-bottom: 40px;
`
const RedactedArea = styled.div`
  width: ${props => props.length * 9}px;
  height: 20px;
  background-color: black;
`


const FlexSpaceWrapperCenter = styled(FlexSpaceBetweenWrapper)`
  align-items: center;
`;

const FlexSpaceBetweenWrapperWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MiddleAligner = styled.div`
  display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	align-content: stretch;
  height: calc(100vh - 60px);
`;

const Gutter = styled.div`
  height: ${props => props.h}px;
`;

const Container = styled.div`
  padding: 30px;
`;

const TitleArea = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

const SectionAreaColumn = styled.div`
  display: flex;
`

export {
  FlexSpaceBetweenWrapper,
  FlexSpaceWrapperCenter,
  Gutter,
  Container,
  MiddleAligner,
  TitleArea,
  SectionAreaColumn,
  RecordColumn,
  RedactedArea,
  FlexSpaceBetweenWrapperWrap
};