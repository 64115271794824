import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';
import { tablize } from '../utils';
import { changeUser } from '../actions';
import _ from 'lodash';

const Fragment = React.Fragment;

const SelectButton = styled.button`
  display:block;
  width: 250px;
  text-align: left;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #222;
    color: white;
  }
  
  &.selected {
    background-color: black;
    color: white;
  }
`;

class DemographicSelect extends Component {
  constructor(props){
    super(props);
    this.state = {
      buttonDisabled: false
    }
  }
  async postDemographics(value){

    let { variableName } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };
    
    let formData = new FormData();    
    formData.append(`user[${variableName}]`, value);

    const response = await fetch(`/api/users.json`, { method: "put", body: formData, headers });

    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeUser(data.user));
      this.setState({
        buttonDisabled: false
      })

    } else {
      let data = await response.json();
      console.log(data);
      this.setState({
        buttonDisabled: false
      })
      return data;

    }

  }

  handleClick(value){
    if (!this.state.buttonDisabled) {
      this.setState({
        buttonDisabled: true
      })
      this.postDemographics(value);
    }

  }

  render() {
    let { options } = this.props;
    return (
      <Fragment>
        {
          _.map(options, (option, idx) => {
            let { currentUser, variableName } = this.props;
            let selected = false;
            if (!_.isNull(currentUser[variableName])) {
              selected = currentUser[variableName] === option.value;
            }
            
            return (
              <SelectButton key={tablize(option.value)} className={selected ? "selected" : ""} style={{ borderBottom: idx === options.length - 1 ? "1px solid black" : "none"}} onClick={this.handleClick.bind(this, option.value)}>
                {option.label}
              </SelectButton>
            )
          })
        }
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    csrfToken: state.csrfToken
  }
}

export default connect(mapStateToProps)(DemographicSelect);