import React from 'react';
import { windowResize } from '../../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch, Link } from "react-router-dom";
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Gutter, FlexSpaceBetweenWrapperWrap } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';
import '../../../../../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries} from 'react-vis';
import { bin } from 'd3-array';

const Fragment = React.Fragment;
const containerWidth = 1000;
const firstcolumnWidth = 100;
const columnWidth = (containerWidth - firstcolumnWidth) / 3;
const padding = 20;

const SelectArea = styled.div`
  position: fixed;
  width: 200px;
  height: 20px;
  right: 20px;
  top: 20px;

  select {
    width: 200px;
    padding: 10px;
  }
`

const Wrapper = styled.div`
  width: ${containerWidth}px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #CCC;
  margin-bottom: 30px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }
`;

const GraphWrap = styled.div`
  /* border-right: 1px dashed #AAA; */
  width: ${columnWidth}px;
`

const Label = styled.div`

  text-align: center;
  font-size: 0.9em;
  color: #000;
  line-height: 1.3;
  margin-bottom: 10px;
  width: ${columnWidth}px;
  padding-left: 20px;

`;

const ButtonColumn = styled.div`

`
const GraphArea = styled.div`
  margin-right: 30px;
  margin-bottom: 30px;

`
const GraphLabel = styled.div`
  text-align: center;
  font-size: 0.8em;
  line-height: 1.2;
  color: #000;
  padding-left: 31px;
`
const FirstColumnLabel = styled(Label)`
  width: ${firstcolumnWidth}px;
`;




const REPORT_TYPES = [
  { 
    value: "Type 1", label: <>Type 1<br/>Hide Data</>
  },
  { 
    value: "Type 2", label: <>Type 2<br/>Score Data</>
  },
  { 
    value: "Type 3", label: <>Type 3<br/>Show Data</>
  }
]

const SCORE_TYPE = [
  "LOW - CLEAN", "MID", "HIGH"
]



const MapButton = styled.button`
  margin-bottom: 10px;
`

const countDecisions = (decisions, reportType, scoreType) => {
  let result = [
    { x: "Yes", y: 0, },
    { x: "Yes with security deposit", y: 0 },
    { x: "No", y: 0 }
  ];

  _.each(decisions, decision => {
    if (decision.view_mode === reportType && decision.score === scoreType) {
      if (decision.accept_type === "Yes") {
        result[0].y = result[0].y + 1;
      } else if (decision.accept_type === "No") {
        result[2].y = result[2].y + 1;
      } else {
        result[1].y = result[1].y + 1;
      }
    }
  })

  return result;
}

const calculateHistogram = (decisions, reportType, scoreType) => {
  let filteredDecisions = _.filter(decisions, decision => {
    return decision.view_mode === reportType && decision.score === scoreType;
  });

  let bins = [
    {x: "Yes", y: 0, range: [0, 0]},
    {x: "0.5m", y: 0, range: [0, 0.5]},
    {x: "1m", y: 0, range: [0.5, 1.0]},
    {x: "1.5m", y: 0, range: [1.0, 1.5]},
    {x: "2m", y: 0, range: [1.5, 2.0]},
    {x: "2.5m", y: 0, range: [2.0, 2.5]},
    {x: "3m", y: 0, range: [2.5, 3.0]},
    {x: "No", y: 0, range: [100, 100]}
  ];

  _.each(filteredDecisions, fd => {

    if (fd.accept_type === "Yes") {

      bins[0].y = bins[0].y + 1;

    } else if (fd.accept_type === "No") {

      bins[7].y = bins[7].y + 1;
    
    } else {

      for (let i = 0; i < bins.length; i++) {
        if (fd.security_deposit > bins[i].range[0] && fd.security_deposit <= bins[i].range[1]){
          bins[i].y = bins[i].y + 1;
        }
      }
    }
  })

  return bins;
}

const modeOptions = [
  { value: "all", label: "All" },
  { value: "Criminal", label: "Criminal" },
  { value: "Eviction", label: "Eviction" },
  { value: "Date: Old Records", label: "Old Records" },
  { value: "Date: Recent Records", label: "Recent Records" },
  { value: "Date: COVID Evictions", label: "COVID Evictions" }
  
]

class DashboardAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      decisions: [],
      mode: "all"
    }
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler(); 
    this.loadData();
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
  }

  async loadData(){
    const headers = {
      'X-Requested-With': 'XMLHttpRequest'
    };
    
    const response = await fetch(`/api/dashboard_admin.json?mode=${this.state.mode}`, { method: "get", headers });

    if (response.ok) {
      let data = await response.json();
      // console.log(data);

      this.setState({
        decisions: data
      });

    } else {
      let data = await response.json();
      return data;

    }

  }

  handleTypeClick(){

  }

  handleModeChange(e){
    this.setState({
      mode: e.target.value
    }, () => {
      this.loadData();
    });
  }


  render() {
    let { decisions } = this.state;

    return (
      <>

        <SelectArea>
          <select value={this.state.mode} onChange={this.handleModeChange.bind(this)}>
            {
              _.map(modeOptions, option => {
                return (<option key={option.value} value={option.value}>{option.label}</option>);
              })
            }
          </select>
        </SelectArea>

        {
          decisions.length > 0 ? 
          <div>
            <Gutter h={50} />
            <Wrapper>
              <FirstColumnLabel>
              </FirstColumnLabel>
              {
                _.map(REPORT_TYPES, reportType => {

                  return (
                    <Label>
                      {reportType.label}
                    </Label>
                  )
                })
              }
            </Wrapper> 
            {
              _.map(SCORE_TYPE, scoreType => {
                return (
                  <>
                    <Wrapper>
                      <FirstColumnLabel>
                        {scoreType}
                      </FirstColumnLabel>
                      {
                        _.map(REPORT_TYPES, reportType => {

                          let countedDecisions = countDecisions(decisions, reportType.value, scoreType);
                          let securityDepositHistogram = calculateHistogram(decisions, reportType.value, scoreType);
                          return (
                            <GraphWrap key={`${reportType.value}-${scoreType}`}>
                              {/* <GraphArea>
                                <XYPlot xType="ordinal" height={150} width={columnWidth - padding} yDomain={[0, 40]} fill="#b2a6b4" stroke="#999999">
                                  <HorizontalGridLines />
                                  <VerticalBarSeries data={countedDecisions} />
                                  <XAxis />
                                  <YAxis />
                                </XYPlot>
                                <GraphLabel>
                                  {reportType.value} / {scoreType}
                                </GraphLabel>
                              </GraphArea> */}
                              <GraphArea>
                                <XYPlot xType="ordinal" height={200} yDomain={[0, this.state.mode === "all" ? 40 : 15]} width={columnWidth - padding}>
                                  <HorizontalGridLines />
                                  <VerticalBarSeries data={securityDepositHistogram} />
                                  <XAxis />
                                  <YAxis />
                                </XYPlot>
                                <GraphLabel>
                                  Distribution of<br/>Yes-Conditional Yes-No
                                </GraphLabel>
                              </GraphArea>
                            </GraphWrap>
                          )
                        })
                      }
                    </Wrapper> 
                  </>
                );
                
              })
            }
          </div> : null
        }
      </>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight
  }
}

export default withRouter(connect(mapStateToProps)(DashboardAdmin));