export const windowResize = (dimension) => {
  return {
    type: 'WINDOW_RESIZE',
    payload: {
      windowWidth: dimension.width,
      windowHeight: dimension.height
    }
  }
}

export const changeMapLoaded = (mapLoaded) => {
  return {
    type: 'CHANGE_MAP_LOADED',
    payload: {
      mapLoaded: mapLoaded
    }
  }
}




export const changeSessions = (loggedIn, currentUser, csrfToken) => {
  return {
    type: 'CHANGE_SESSIONS',
    payload: {
      loggedIn: loggedIn,
      currentUser: currentUser,
      csrfToken: csrfToken
    }
  }
}

export const changeUser = (currentUser) => {
  return {
    type: 'CHANGE_USER',
    payload: {
      currentUser: currentUser
    }
  }
}




export const changeDecisions = (decisions) => {
  return {
    type: 'CHANGE_DECISIONS',
    payload: {
      decisions: decisions
    }
  }
}


export const changeForkingroomDecision = (forkingroomDecision) => {
  return {
    type: 'CHANGE_FORKINGROOM_DECISION',
    payload: {
      forkingroomDecision: forkingroomDecision
    }
  }
}

export const changeForkingroomCities = (forkingroomCities) => {
  return {
    type: 'CHANGE_FORKINGROOM_CITIES',
    payload: {
      forkingroomCities: forkingroomCities
    }
  }
}


export const changeForkingroomCurrentCity = (forkingroomCurrentCity) => {
  return {
    type: 'CHANGE_FORKINGROOM_CURRENT_CITY',
    payload: {
      forkingroomCurrentCity: forkingroomCurrentCity
    }
  }
}