import React from 'react';
import { windowResize, changeBusinessesData, changeEcosystemsData } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch, Link } from "react-router-dom";
import { withRouter } from 'react-router';
import _ from 'lodash';
import FirstPage from './FirstPage';
import { Gutter } from '../stylesheets/components';
import { API_URL } from '../constants/defaults';

const Fragment = React.Fragment;
class Welcome extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount(){
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler(); 
  }


  resizeHandler(e){
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));
    
  }



  render() {
    return (
      <Fragment>
        <Switch>
          <Route exact path="/">
            <FirstPage />
          </Route>

        </Switch>

        
      </Fragment>
    );
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    mapLoaded: state.mapLoaded
  }
}

export default withRouter(connect(mapStateToProps)(Welcome));