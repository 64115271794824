import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { changeUser } from '../../actions';
import { DemographicSelect } from '../../components';
import { Gutter, Container, MiddleAligner } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';

const Fragment = React.Fragment;

const numbersOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 }
]

const yesNoOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" }
]


class OpenQuestionsPage extends Component {
  constructor(props){
    super(props);

  }
  componentDidMount(){
    this.loadData();
  }
  
  async loadData(){
    // let url = `${API_URL}/api/firstpage/all.json`;

    // const headers = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'X-Requested-With': 'XMLHttpRequest'
    // }
    // // this.props.dispatch(changeAnimationStatus("stop"));
    // // this.props.dispatch(changeProgress(true));
    // const response = await fetch(url, { method: "get", headers });
    // if (response.ok) {
    //   let data = await response.json();
      
    // } else {
    //   let data = await response.json();
    //   return data;

    // }
  }

  handleClick(e){
    if (this.validateAll()){
      this.props.history.push("/survey/done");
    } else {
    }
  }

  validateAll() {
    let { currentUser, decisions } = this.props;

    let currentUserValidate;
    let decisionsValidate = true;

    if (currentUser.is_currently_renting === true) {
      currentUserValidate = currentUser.is_currently_renting === true && !_.isNull(currentUser.number_of_unit) && !_.isNull(currentUser.gender) && currentUser.logic && !_.isNull(currentUser.ethnicity) && !_.isNull(currentUser.income) && !_.isNull(currentUser.how_clear_the_experiment) && !_.isNull(currentUser.how_enjoyable_the_experiment) && !_.isNull(currentUser.okay_blanket_policy);
    } else {
      currentUserValidate = !_.isNull(currentUser.okay_blanket_policy) && !_.isNull(currentUser.is_currently_renting) && !_.isNull(currentUser.gender) && !_.isNull(currentUser.ethnicity) && currentUser.logic && !_.isNull(currentUser.logic) && !_.isNull(currentUser.income) && !_.isNull(currentUser.how_clear_the_experiment) && !_.isNull(currentUser.how_enjoyable_the_experiment)
    }

    _.each(decisions, decision => {
      if (decision.accept_type === "Yes with security deposit"){
        if (!(decision.accept_type && decision.security_deposit)){
          decisionsValidate = false;
        }
      } else {
        if (!decision.accept_type){
          decisionsValidate = false;
        }
      }
    });

    return currentUserValidate && decisionsValidate;

  }

  updateText(e){
    let value = e.target.value;

    let { currentUser } = this.props;

    let newCurrentUser = {
      ...currentUser,
      logic: value
    };
    this.props.dispatch(changeUser(newCurrentUser));
  }

  async uploadLogic(e){
    let value = e.target.value;

    let { csrfToken } = this.props;

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };
    
    let formData = new FormData();    
    formData.append(`user[logic]`, value);

    const response = await fetch(`/api/users.json`, { method: "put", body: formData, headers });

    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeUser(data.user));

    } else {
      let data = await response.json();
      console.log(data);
      return data;

    }

  }


  render() {
    let { loggedIn, currentUser } = this.props;
    return (
      <Container>
        {
          loggedIn ? 
          <div>
            <h1>
              Last Step: Open Ended Questions
            </h1>
            <p>
              Do you think it is okay for a landlord to never rent to people with a criminal or eviction record?
            </p>
            <DemographicSelect 
              variableName="okay_blanket_policy" 
              options={yesNoOptions} />
            <Gutter h={30} />

            <p>
              Can you describe your overall logic for selecting tenants who are acceptable?
            </p>
            <textarea placeholder="Type here" value={currentUser.logic ? currentUser.logic : ""} onChange={this.updateText.bind(this)} onBlur={this.uploadLogic.bind(this)}>

            </textarea>

            

            <Gutter h={30} />
            <p>
              How clear was the experiment? <br/>
              (1 = very unclear, 5 = very clear)
            </p>
            <DemographicSelect 
              variableName="how_clear_the_experiment" 
              options={numbersOptions} />

            <Gutter h={30} />
            <p>
              How enjoyable was the experiment? <br/>
              (1 = not enjoyable, 5 = very enjoyable)
            </p>
            
            <DemographicSelect 
              variableName="how_enjoyable_the_experiment" 
              options={numbersOptions} />

            <Gutter h={20} />
            <button disabled={!this.validateAll()} onClick={this.handleClick.bind(this)}>
              Next &gt;
            </button>
          </div> : null
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    currentUser: state.currentUser,
    csrfToken: state.csrfToken
  }
}

export default withRouter(connect(mapStateToProps)(OpenQuestionsPage));