import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../../utils';
import _ from 'lodash';
import { changeUser } from '../../actions';
import { Container, Gutter, MiddleAligner } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';

const Fragment = React.Fragment;


class ConsentPage extends Component {
  constructor(props){
    super(props);
    this.state = {
      buttonDisabled: false
    }
  }
  async postConsent(){
    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'X-Requested-With': 'XMLHttpRequest'
    };
    
    let formData = new FormData();    
    formData.append("user[consented]", true);
    formData.append("user[consented_at]", new Date().toISOString());
    formData.append("user[is_currently_renting]", true);

    const response = await fetch(`/api/users.json`, { method: "put", body: formData, headers });

    if (response.ok) {
      let data = await response.json();
      this.props.dispatch(changeUser(data.user));
      this.props.history.push("/survey/introduction");

    } else {
      let data = await response.json();
      console.log(data);
      this.setState({
        buttonDisabled: false
      });

      return data;

    }

  }

  handleClick(e){
    let { sessionSynced, loggedIn } = this.props;
    let { buttonDisabled } = this.state;

    if (!buttonDisabled && sessionSynced) {
      this.setState({
        buttonDisabled: true
      });

      this.postConsent();
    }
  }


  render() {
    let { currentUser, loggedIn } = this.props;
    return (
      <Container>
        <MiddleAligner>
          <div>

            {
              loggedIn ? 
              <Fragment>
                <h1>
                  Thanks for entering the survey.
                </h1>
                <p>
                  Your session ID is { currentUser.uuid }. Keep it in case of problems.
                </p>
                <p>
                  This HIT is part of an MIT research project. Your decision to complete this HIT is voluntary. There is no way for us to identify you. The only information we will have, in addition to your responses, is the time at which you completed the survey. The results of the research may be presented at scientific meetings or published in scientific journals. 
                </p>
                <p>
                  Compensation<br/>
                  The compensation per one HIT is <u>$4.00</u>. You have to spend <u>minimum 13 mins (30 seconds per 1 report * 25 reports)</u> or your time for the main experiment. The total duration should not be exceeded <u>60 mins</u>. Please read each report carefully.
                </p>
                <p>
                  Clicking on the 'Okay' button on the bottom of this page indicates that you understand and agree to the compensation offer, you are at least 18 years of age, and agree to complete this HIT voluntarily.
                </p>
                <Gutter h={20} />
                <button onClick={this.handleClick.bind(this)}>
                  Okay &gt;
                </button>
              </Fragment>: null
            }
          </div>
        </MiddleAligner>        
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    sessionSynced: state.sessionSynced,
    currentUser: state.currentUser,
    csrfToken: state.csrfToken
  }
}

export default withRouter(connect(mapStateToProps)(ConsentPage));