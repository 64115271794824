import DemographicSelect from './DemographicSelect';
import DecisionSelect from './DecisionSelect';
import ReportArea from './ReportArea';
import Header from './Header';
import CreditScoreArea from './CreditScoreArea';
import EvictionRecordArea from './EvictionRecordArea';
import CriminalRecordArea from './CriminalRecordArea';
import Type2RenderRecords from './Type2RenderRecords';
import ForkingroomDecisionSelect from './ForkingroomDecisionSelect';
export {
  DecisionSelect,
  DemographicSelect,
  ReportArea,
  Header,
  CreditScoreArea,
  EvictionRecordArea,
  CriminalRecordArea,
  Type2RenderRecords,
  ForkingroomDecisionSelect
};