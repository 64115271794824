import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeMapLoaded } from '../actions';
import _ from 'lodash';
import styled from 'styled-components';
import media from '../stylesheets/media';
import { API_URL } from '../constants/defaults'

// const Fragment = React.Fragment;
const MapDiv = styled.div`
  width: 100%;
  height: 100vh;
`;

const popBlackRatioLayer = [
  "step",
  ["get", "pop_black_ratio"],
  "#ffffcc", 0.1,
  "#d6efc2", 0.2,
  "#acdeb7", 0.3,
  "#81ceba", 0.4,
  "#56bec1", 0.5,
  "#3caac2", 0.6,
  "#3391bc", 0.7,
  "#2b77b4", 0.8,
  "#2855a4", 0.9,
  "#173b7b", 1,
  "#ccc"
];

const popLatinoRatioLayer = [
  "step",
  ["get", "pop_latino_ratio"],
  "#ffffcc", 0.1,
  "#d6efc2", 0.2,
  "#acdeb7", 0.3,
  "#81ceba", 0.4,
  "#56bec1", 0.5,
  "#3caac2", 0.6,
  "#3391bc", 0.7,
  "#2b77b4", 0.8,
  "#2855a4", 0.9,
  "#173b7b", 1,
  "#ccc"
];

const evictionCaseLayer = [
  "step",
  ["to-number", ["get", "eviction_ratio"], 0],
  "#ffffcc", 0.1,
  "#d6efc2", 0.2,
  "#acdeb7", 0.3,
  "#81ceba", 0.4,
  "#56bec1", 0.5,
  "#3caac2", 0.6,
  "#3391bc", 0.7,
  "#2b77b4", 0.8,
  "#2855a4", 0.9,
  "#173b7b", 1,
  "#ccc"
];

class MapContainer extends Component {
  constructor(props){
    super(props);
    this.hoveredGeoid = null;
  }
  componentDidMount() {
    window.mapboxgl.accessToken = process.env.MAPBOX_TOKEN;
    
    this.map = new window.mapboxgl.Map({
      container: this.refsMapContainer,
      style: process.env.MAPBOX_STYLE,
      zoom: 11,
      minZoom: 4,
      center: [ -71.07370855136972, 42.35769963575217]
      // scrollZoom: false
      // interactive: false
    });

    this.map.addControl(new window.mapboxgl.NavigationControl());

    window[`map_${this.mode}`] = this.map;
    this.map.on('style.load', this.handleStyleLoad.bind(this));
  
  }

  componentDidUpdate(prevProps){
    if (this.map.isStyleLoaded()){  

      if (prevProps.mode !== this.props.mode) {
        
        let fillColorLayer; 
        if (this.props.mode === "blackpop") {
          fillColorLayer = popBlackRatioLayer;
        } else if (this.props.mode === "latinopop") {
          fillColorLayer = popLatinoRatioLayer;
        } else {
          fillColorLayer = evictionCaseLayer;
        }

        this.setPaintProperty('census_tracts_layer', fillColorLayer);

      }

      if (prevProps.forkingroomCurrentCity !== this.props.forkingroomCurrentCity) {
        this.updateBounds();
      }

    }
  }

  updateBounds(){
    let { forkingroomCurrentCity, forkingroomCities } = this.props;
    let currentCity = forkingroomCities[forkingroomCurrentCity];

    console.log(currentCity);
    this.map.fitBounds([
      [currentCity.bbox[0], currentCity.bbox[1]],
      [currentCity.bbox[2], currentCity.bbox[3]]
    ]);

  }

  async handleStyleLoad(e) {
    let fillColorLayer; 

    this.map.addSource('census_tracts', {
      "type": 'vector',
      "tiles": [`${API_URL}/tiles/census_tracts/{z}/{x}/{y}.pbf`],
      "minZoom": 7,
      "maxZoom": 22
    });

    
    if (this.props.mode === "blackpop") {
      fillColorLayer = popBlackRatioLayer;
    } else if (this.props.mode === "latinopop") {
      fillColorLayer = popLatinoRatioLayer;
    } else {
      fillColorLayer = evictionCaseLayer;
    }

    this.map.addLayer({
      'id': 'census_tracts_layer',
      'type': 'fill',
      'source': 'census_tracts',
      'source-layer': 'public.census_tracts',
      'layout': {},
      'paint': {
        'fill-color': fillColorLayer,
        'fill-opacity':  [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          1,
          0.7
        ],
        'fill-outline-color': 'rgba(30, 30, 30, 0.1)'
      }
    }, 'admin-0-boundary-disputed');

    this.map.on('mousemove', 'census_tracts_layer', (e) => {
      if (e.features.length > 0) {
        console.log(e.features[0].properties)
        if (this.hoveredGeoid) {
          this.map.setFeatureState(
            { source: 'census_tracts', sourceLayer: 'public.census_tracts', id: this.hoveredGeoid },
            { hover: false }
          );
        }
        this.hoveredGeoid = e.features[0].id;
        
        this.map.setFeatureState(
          { source: 'census_tracts', sourceLayer: 'public.census_tracts', id: this.hoveredGeoid },
          { hover: true }
        );
      }
      
      this.map.getCanvas().style.cursor = 'pointer';
    });

    this.map.on('mouseleave', 'census_tracts_layer', () => {
      if (this.hoveredGeoid) {
        this.map.setFeatureState(
          { source: 'census_tracts', sourceLayer: 'public.census_tracts', id: this.hoveredGeoid },
          { hover: false }
        );
      }
      this.hoveredGeoid = null;
      this.map.getCanvas().style.cursor = 'auto';
    });
    this.props.dispatch(changeMapLoaded(true));
  }

  

  render() {
    let { isFullScreen } = this.props;

    return (
      <MapDiv isFullScreen={isFullScreen} ref={c => { this.refsMapContainer = c; }} className="map-container">
      </MapDiv>
    );
  }
}

let mapStateToProps = state => {

  return {
    windowWidth: state.windowWidth,
    windowHeight: state.windowHeight,
    forkingroomCurrentCity: state.forkingroomCurrentCity,
    forkingroomCities: state.forkingroomCities
  }
}

export default connect(mapStateToProps)(MapContainer);