import React, { Component } from 'react'
import { connect } from 'react-redux';
import { TitleArea, RedactedArea, FlexSpaceBetweenWrapperWrap, RecordColumn } from '../stylesheets/components';

import styled from 'styled-components';
import moment from 'moment';

const Fragment = React.Fragment;

class EvictionRecordArea extends Component {
  renderEvictionRecordType1(){

    let { decision, currentUser } = this.props;

    return (
      <FlexSpaceBetweenWrapperWrap>
       <RecordColumn>
          Name Searched<br/>
          <RedactedArea length={ decision.name} />
        </RecordColumn>

        <RecordColumn>
          SSN Searched<br/>
          { decision.ssn}
        </RecordColumn>

        <RecordColumn>
          Case Number<br/>
          { decision.eviction_case_number}
        </RecordColumn>
        <RecordColumn>
          Case Type<br/>
        </RecordColumn>

        <RecordColumn>
          Court Name<br/>
          { decision.eviction_court_name }
        </RecordColumn>

        <RecordColumn>
          Filed Amount<br/>
        </RecordColumn>
        <RecordColumn>
          Filed Date<br/>
        </RecordColumn>

        <RecordColumn>
          Judgement<br/>
        </RecordColumn>

        <RecordColumn>
          Judgement Date<br/>
        </RecordColumn>

        <RecordColumn>
          Plaintiff<br/>
          {
            decision.eviction_plaintiff_name ? 
            decision.eviction_plaintiff_name : null
          }
        </RecordColumn>
      </FlexSpaceBetweenWrapperWrap>
    )
  }
  renderEvictionRecord(){

    let { decision } = this.props;

    return (
      <FlexSpaceBetweenWrapperWrap>
        <RecordColumn>
          Case Number<br/>
          { decision.eviction_case_number}
        </RecordColumn>
        <RecordColumn>
          Case Type<br/>
          { decision.eviction_case_type}
        </RecordColumn>

        <RecordColumn>
          Court Name<br/>
          { decision.eviction_court_name }
        </RecordColumn>

        <RecordColumn>
          Filed Amount<br/>
          {
            decision.eviction_filling_amount ? 
            <>${decision.eviction_filling_amount}</> : null
          }
        </RecordColumn>
        <RecordColumn>
          Filed Date<br/>
          {
            decision.eviction_filling_date ? 
            moment(decision.eviction_filling_date).format("MM-DD-Y") : null
          }
        </RecordColumn>

        <RecordColumn>
          Judgement<br/>
          {
            decision.eviction_judgement_amount ? 
            decision.eviction_judgement_amount : null
          }
        </RecordColumn>

        <RecordColumn>
          Judgement Date<br/>
          {
            decision.eviction_judgement_date ? 
            moment(decision.eviction_judgement_date).format("MM-DD-Y") : null
          }
        </RecordColumn>

        <RecordColumn>
          Plaintiff<br/>
          {
            decision.eviction_plaintiff_name ? 
            decision.eviction_plaintiff_name : null
          }
        </RecordColumn>
      </FlexSpaceBetweenWrapperWrap>
    )

  }

  render() {
    let { decision, currentUser } = this.props;
    return (
      <Fragment>

        <TitleArea>
          Eviction Records 
          {
            decision.report_type === "Eviction" ? 
              <> 1 of 1</>
              : null
          }
        </TitleArea>
        {
          decision.report_type === "Eviction" ?
          <Fragment>
            {
              currentUser.view_mode === "Type 1" ?
              this.renderEvictionRecordType1()
              :
              this.renderEvictionRecord()
            }
          </Fragment> : 
          <Fragment>
            No Eviction Record Found.
          </Fragment>
        } 
      </Fragment>
    )
  }
}

let mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps)(EvictionRecordArea);