import React, { Component, useContext } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { constrain } from '../../utils';
import _ from 'lodash'
import { Container, Gutter } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';
import { ForkingroomDecisionSelect, ReportArea } from '../../components';
import { changeForkingroomDecision, changeSessions } from '../../actions';
import Shepherd from 'shepherd.js';
const Fragment = React.Fragment;
const TIME_LIMIT = 60;

const NavigateArea = styled.div`
  display: flex;
  position: fixed;
  right: 30px;
  top: 80px;
  align-items: center;
  justify-content: space-between;
`;

const NavigateIndicator = styled.div`
  margin: 0 10px;
`;

const ReportAreaPseudo = styled.div`
  position:absolute; 
  left: 30px;
  top: 80px;
  width:calc(100vw - 250px);
  max-width: 1200px;
  height: calc(100vh - 320px);
`;

const DecisionArea = styled.div`
  border-top: 1px solid black;
  background-color: #FAF3E6;
  padding: 20px 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100vw - 40px);
  height: 200px;
`;

const ValidateButton = styled.button`

`;

const ValidateArea = styled.div`
  display: flex;
  align-items: center;
`
const ValidateTimer = styled.div`
  margin-left: 10px;
  font-size: 0.7em;
`

class ForkingroomSurveyPage extends Component {
  constructor(props){
    super(props);
    this.state = {  
      currentDecision: null
    }

  }
  componentDidMount(){
    this.loadData();

    setTimeout(() => {
      document.location.reload();
    }, 1800000);
    // if (this.props.csrfToken) {
    //   this.initializeReports();
    // }
  }

  async loadData(){
    let url = `${API_URL}/api/forkingroom_survey_sessions.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(url, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      
      this.props.dispatch(changeSessions(data.logged_in, data.current_user, data.csrf_token));
      this.props.dispatch(changeForkingroomDecision(data.current_decision));

      
    } else {
      let data = await response.json();
      return data;

    }
  }


  async loadNext(){
    let url = `${API_URL}/api/forkingroom_survey_get_new_decision.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(url, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      
      this.props.dispatch(changeForkingroomDecision(data.current_decision));

      
    } else {
      let data = await response.json();
      return data;

    }
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps){
  }

  handleNextClick(e){
    this.loadNext(); 
  }

  validateDecision(decision) {
    if (decision.accept_type === "Yes with security deposit"){
      return decision.accept_type && decision.security_deposit;
    } else {
      return decision.accept_type;
    }
  }

  determineNextAvailable(){
    let { loggedIn, forkingroomDecision } = this.props;

    return this.validateDecision(forkingroomDecision);
  }
  
  render() {
    let { loggedIn, forkingroomDecision  } = this.props;
    return (
      <Container>
        {
          loggedIn ? 
          <Fragment>
            {
              forkingroomDecision? 
              <Fragment>
                <ReportArea decision={forkingroomDecision} />

                <DecisionArea className="decision-area">
                  <p>
                    Would you rent your property to this tenant?
                  </p>
                  <ForkingroomDecisionSelect decision={forkingroomDecision} />
                  <Gutter h={10} />
                  
                  <ValidateArea>
                    <ValidateButton disabled={!this.determineNextAvailable()} onClick={this.handleNextClick.bind(this)}>
                      Next &gt;
                    </ValidateButton>
                  </ValidateArea>
                </DecisionArea>
              </Fragment> : 
              <Fragment>
                Loading...
              </Fragment>
            }
           
          </Fragment> : null
        }
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    csrfToken: state.csrfToken,
    currentUser: state.currentUser,
    forkingroomDecision: state.forkingroomDecision
  }
}

export default withRouter(connect(mapStateToProps)(ForkingroomSurveyPage));