import React, { Component } from 'react'
import welcomeReducer from './reducers/welcomeReducer';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import Welcome from './pages/Welcome';
import { BrowserRouter as Router } from "react-router-dom";
import "./stylesheets/reset.scss";
import "./stylesheets/fonts.scss";
import "./stylesheets/application.scss";

export default class App extends Component {
  componentDidMount() {

  }
  render() {

    return (
      <Provider store={createStore(welcomeReducer)}>
        <Router>
          <Welcome />
        </Router>
      </Provider>
    )
  }
}