import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { numberWithDelimiter, tablize } from '../../utils';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Container, Gutter, MiddleAligner } from '../../stylesheets/components';
import { API_URL } from '../../constants/defaults';

const Fragment = React.Fragment;

const HitKeyP = styled.p`
  font-size: 2.0em;
`;
class DonePage extends Component {
  constructor(props){
    super(props);
    this.state = {
      hitKey: null,
      errors: null,
      signedOutIntentionally: false
    }
  }
  componentDidMount(){
    this.loadHitKey();
  }

  componentDidUpdate(){
    // this.loadHitKey();
  }
  
  async loadHitKey(){
    let url = `${API_URL}/api/users/hitkey.json`;

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(url, { method: "get", headers });
    if (response.ok) {
      let data = await response.json();
      if (data.success) {

        this.setState({
          hitKey: data.hit_key
        })

      } else {
        this.setState({
          errors: data.errors
        })
      }
    } else {
      let data = await response.json();
      return data;

    }
  }

  async handleSignOut(){
    let url = `${API_URL}/api/users/signout.json`;

    const headers = {
      'X-CSRF-Token': this.props.csrfToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    // this.props.dispatch(changeAnimationStatus("stop"));
    // this.props.dispatch(changeProgress(true));
    const response = await fetch(url, { method: "DELETE", headers });
    if (response.ok) {
      let data = await response.json();
      this.setState({
        signedOutIntentionally: true
      })
    } else {
      let data = await response.json();
      return data;

    }
  }
  render() {
    let { signedOutIntentionally } = this.state;
    let { loggedIn, currentUser } = this.props;
    return (
      <Container>
        <MiddleAligner>
          {
            loggedIn ? 
            <div>
              <h1>
                Done!
              </h1>

              <p>
                Thanks for participating. Please copy and paste the code below to the MTurk interface.
              </p>
              {
                this.state.errors ? 
                <Fragment> 
                  <p>
                    Errors occured during the validation process. To complete the HIT, please refer to the error messages below.
                  </p>
                  {
                    _.map(this.state.errors, (v, k) => {
                      return (
                        <p key={k}>
                          -<br/>
                          { v.message } <Link to={v.url}>Go to the page</Link>
                        </p>

                      )
                    })
                  }
                </Fragment> : null
              }
              {
                this.state.hitKey ? 
                <Fragment>
                  <HitKeyP>
                    { this.state.hitKey}
                  </HitKeyP>
                  {
                    signedOutIntentionally ?
                    <Fragment>
                      <p>
                        You signed out successfully.
                      </p>
                    </Fragment>
                    : 
                    <Fragment>
                      <p>
                        After you copy the code above, you can sign out this session by clicking the "Sign Out" button below.
                      </p>
                      <button onClick={this.handleSignOut.bind(this)}>
                        Sign Out
                      </button>
                    </Fragment>
                  }
                 
                </Fragment> :
                <Fragment>
                  {
                    this.state.errors ? 
                    null : 
                    <p>
                      Fetching the hit key...
                    </p>
                  }
                </Fragment> 
              }
              
            </div> : null
          }
        </MiddleAligner>
      </Container>
    )
  }
}

let mapStateToProps = state => {
  return {
    windowWidth: state.windowWidth,
    loggedIn: state.loggedIn,
    currentUser: state.currentUser,
    csrfToken: state.csrfToken
  }
}

export default withRouter(connect(mapStateToProps)(DonePage));